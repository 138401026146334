<template>
  <div style="width: 1200px; margin: 20px auto; min-height: calc(100vh - 100px)">
    <el-card style="height: 70px">
      <el-button type="primary" @click="createUser">创建账户</el-button>
      <el-button type="primary" @click="handleEdit()">编辑账户</el-button>
      <el-button type="primary" @click="handleDelete()">删除账户</el-button>
      <el-button type="primary" class="import-btn" @click="importDevice()">
        <span class="import-span">设备导入</span>
        <input
          class="import-input"
          ref="fileRef"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="updateDevice($event)"
          type="file"
        />
      </el-button>

      <el-button style="float: right" type="primary" @click="frontDownload"
        >下载账户设备数据</el-button
      >
    </el-card>
    <div
      style="
        background: #fff;
        padding: 20px;
        margin-top: 20px;
        height: calc(100vh - 235px);
      "
    >
      <el-table
        @select="dialogCheck"
        style="width: 1160px"
        :data="tableData"
        ref="userTab"
        height="440"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column :label="item" v-for="(item, index) in tableHead" :key="index">
          <template v-if="index < 4" #default="scope">
            {{ scope.row[index + 1] }}
          </template>
          <template v-if="index == 4" #default="scope">{{
            scope.row[index + 2]
          }}</template>
        </el-table-column>
      </el-table>

      <ElPagination
        style="margin-top: 20px"
        :total="totalNum"
        :current-page="pageNum + 1"
        :page-count="pageSize"
        @current-change="currentChange"
      ></ElPagination>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="创建账号"
      width="500px"
      :before-close="handleClose"
    >
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
        :size="formSize"
      >
        <el-form-item label="所属分区">
          <el-input v-model="ruleForm[0]" />
        </el-form-item>
        <el-form-item label="账户名称">
          <el-input v-model="ruleForm[1]" />
        </el-form-item>
        <el-form-item label="账户类型">
          <el-input disabled v-model="userType" />
        </el-form-item>
        <el-form-item label="账户密码">
          <el-input v-model="ruleForm[2]" />
        </el-form-item>
        <!-- <el-form-item label="设备管理">
          <chooseEquipment ref="equipment"></chooseEquipment>
        </el-form-item> -->
        <el-form-item label="电话">
          <el-input v-model="ruleForm[4]" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitUser">提交</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="dialogVisible2"
      title="编辑账号"
      width="500px"
      :before-close="handleClose2"
    >
      <el-form label-width="80px" :model="ruleForm2">
        <el-form-item label="所属分区">
          <el-input v-model="ruleForm2[1]" />
        </el-form-item>
        <el-form-item label="账户名称">
          <el-input v-model="ruleForm2[2]" />
        </el-form-item>
        <el-form-item label="账户类型">
          <el-input disabled v-model="userType" />
        </el-form-item>
        <el-form-item label="账户密码">
          <el-input v-model="ruleForm2[3]" />
        </el-form-item>
        <!-- <el-form-item label="设备管理">
          <chooseEquipment ref="equipment2" :echoData="equipmentData"></chooseEquipment>
        </el-form-item> -->
        <el-form-item label="电话">
          <el-input v-model="ruleForm2[5]" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取消</el-button>
          <el-button type="primary" @click="submitUser2">提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemloginuserList,
  loginsysMod,
  loginsysDel,
  loginsysAdd,
  getAreaDegree2,
  loginsysImport,
  getLoginType,
  loginsysExport,
} from "../utils/api";
import XLSX from "xlsx"; // 引入 xlsx
import xlsx from "xlsx"; // 引入 xlsx
import chooseEquipment from "../components/chooseEquipment.vue";
import { ElMessage, ElMessageBox } from "element-plus/lib/components";
export default {
  components: { chooseEquipment },
  props: {},
  data() {
    return {
      tableHead: ["所属分区", "登录账户", "密码", "创建时间", "电话"],
      tableData: [],
      dialogVisible: false,
      dialogVisible2: false,
      ruleForm: ["", "", "", "", []],
      ruleForm2: [],
      totalNum: 0,
      pageNum: 0,
      partitionData: [],
      defaultProps: {
        label: "name",
        children: "children",
      },
      equipmentData: [],
      propsChe: [],
      userType: "",
    };
  },
  watch: {},
  methods: {
    frontDownload() {
      let exportData = JSON.parse(JSON.stringify(this.$refs.userTab.getSelectionRows()));
      if (exportData.length > 0) {
        loginsysExport({ params: [exportData[0][2]] }).then((res) => {
          // 需要导出的数据源
          var _data = [["IMEI", "iccid1", "有效期"]];
          _data = _data.concat(res.result);
          setTimeout(() => {
            const ws = XLSX.utils.aoa_to_sheet(_data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, exportData[0][2] + "账户的设备列表.xlsx");
          }, 500);
        });
      } else {
        ElMessage({
          type: "error",
          message: "请选择账户",
          customClass: "mzindex",
        });
      }
    },
    updateDevice(e) {
      let importData = JSON.parse(JSON.stringify(this.$refs.userTab.getSelectionRows()));
      //批量上传设备
      if (!event.currentTarget.files.length) {
        return;
      }
      let that = this;
      // 拿取文件对象
      var f = event.currentTarget.files[0];
      // 用FileReader来读取
      var reader = new FileReader();
      // 重写FileReader上的readAsBinaryString方法
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var wb; // 读取完成的数据
        var outdata; // 你需要的数据
        var reader = new FileReader();
        reader.onload = function (e) {
          // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // 接下来就是xlsx了，具体可看api
          wb = xlsx.read(binary, {
            type: "binary",
          });
          outdata = xlsx.utils
            .sheet_to_json(wb.Sheets[wb.SheetNames[0]])
            .map((m) => [
              String(m["IMEI"]) ? String(m["IMEI"]) : "",
              String(m["iccid1"]) ? String(m["iccid1"]) : "",
              m["有效期"] ? String(m["有效期"]) : "",
            ]);

          loginsysImport({
            params: [importData[0][2], outdata],
          }).then((res) => {
            if (!res.error) {
              ElMessage({
                type: "success",
                message: "上传成功！",
                customClass: "mzindex",
              });
            }
            that.getList(); //刷新数据
            that.$refs.fileRef.value = ""; //上传后清空值
          });
        };
        reader.readAsArrayBuffer(f);
      };
      reader.readAsBinaryString(f);
    },
    importDevice() {
      let importData = JSON.parse(JSON.stringify(this.$refs.userTab.getSelectionRows()));
      if (importData && importData.length == 1) {
        this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
      } else {
        ElMessage({
          type: "error",
          message: "请先选择要导入数据的账户",
          customClass: "mzindex",
        });
        return false;
      }
    },
    dialogCheck(selection, row) {
      this.$refs.userTab.clearSelection();
      this.selectioned = {}; //这里清除选择的记忆；主要是避免取消掉原来选中的还残留原来选中的东西
      if (selection.length === 0) {
        // 判断selection是否有值存在
        return;
      }
      if (row) {
        this.selectioned = row;
        this.$refs.userTab.toggleRowSelection(row, true);
      }
    },
    createUser() {
      this.dialogVisible = true;
    },
    handleNodeClick(data, checked, node) {
      if (checked) {
        this.$refs.treeForm.setCheckedKeys([data.index]);
      }
    },

    changetree(data, lst) {
      if (lst.checkedKeys.length == 0) {
        //这里的treeForm是你el-tree命名的ref的值
        this.$refs.treeForm.setCheckedKeys([data.index]);
      }
    },
    handleNodeClick2(data, checked, node) {
      if (checked) {
        this.$refs.treeForm2.setCheckedKeys([data.index]);
      }
    },

    changetree2(data, lst) {
      if (lst.checkedKeys.length == 0) {
        //这里的treeForm是你el-tree命名的ref的值
        this.$refs.treeForm2.setCheckedKeys([data.index]);
      }
    },
    getList() {
      systemloginuserList({ params: [-1] }).then((res) => {
        if (!res.error) {
          this.tableData = res.result[0];
          this.totalNum = res.result[1];
          this.pageSize = res.result[2];
        }
      });
    },
    currentChange(val) {
      this.pageNum = val - 1;
      this.getList();
    },
    handleEdit() {
      let editR = JSON.parse(JSON.stringify(this.$refs.userTab.getSelectionRows()));
      editR = editR[0];
      if (editR == null || editR == "" || editR == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择要编辑的用户！",
          customClass: "mzindex",
        });
        return false;
      }
      this.propsChe = [editR[0]];
      this.ruleForm2[1] = editR[1];
      this.ruleForm2[2] = editR[2];
      this.ruleForm2[3] = editR[3];
      this.ruleForm2[4] = editR[5];
      this.ruleForm2[5] = editR[6];
      this.equipmentData = editR[5];

      this.dialogVisible2 = true;
    },
    handleDelete(row) {
      ElMessageBox.confirm("是否删除当前账号！", "提醒", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          let editR = JSON.parse(JSON.stringify(this.$refs.userTab.getSelectionRows()));
          editR = editR[0];
          if (editR == null || editR == "" || editR == undefined) {
            ElMessage({
              type: "error",
              message: "请先选择要删除！",
              customClass: "mzindex",
            });
            return false;
          }
          loginsysDel({ params: [editR[0]] }).then((res) => {
            if (!res.error) {
              ElMessage({
                type: "success",
                message: "删除成功！",
                customClass: "mzindex",
              });
              this.getList();
            }
          });
        })
        .catch((error) => {});
    },

    submitUser() {
      let limits = [];
      // if (this.$refs.equipment.devicelist.length > 0) {
      //   limits = this.$refs.equipment.devicelist
      //     .filter((f) => {
      //       return f[6] === true;
      //     })
      //     .map((m) => {
      //       return m[1];
      //     });
      // }
      this.ruleForm[3] = limits;
      loginsysAdd({ params: this.ruleForm }).then((res) => {
        if (!res.error) {
          this.dialogVisible = false;
          ElMessage({
            message: "提交成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.getList();
        }
      });
    },
    submitUser2() {
      let limits = [];
      // if (this.$refs.equipment2.devicelist.length > 0) {
      //   limits = this.$refs.equipment2.devicelist
      //     .filter((f) => {
      //       return f[6] === true;
      //     })
      //     .map((m) => {
      //       return m[1];
      //     });
      // }
      this.ruleForm2[0] = this.propsChe[0];
      this.ruleForm2[4] = limits;
      loginsysMod({ params: this.ruleForm2 }).then((res) => {
        if (!res.error) {
          this.dialogVisible2 = false;
          ElMessage({
            message: "提交成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.getList();
        }
      });
    },
  },
  created() {
    this.getList();
    getLoginType({ params: [] }).then((res) => {
      this.userType = ["", "一级经销商", "二级经销商", "用户账户"][res.result[0]];
    });
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-table__header .el-checkbox {
  display: none;
}

.import-btn {
  width: 100px;
  overflow: hidden;
  position: relative;
  float: right;
  & .import-span {
    width: 100px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    z-index: 1000;
  }
  & .import-input {
    width: 100px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
  }
}
</style>
